import { fetchRedeemVoucher } from 'fitify-ui-onboarding/src/api/nextApi'
import {
  ContentContainer,
  Heading,
  PageContainer,
  Spinner,
  StyledPrimaryButton,
  Text,
  toast,
  Threshold,
  AnimatedInput,
  HeadingContainer,
} from 'fitify-ui-onboarding/src/components'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { encodeQuery } from 'fitify-ui-onboarding/src/utils'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { Layout } from 'components/Layout'
import { PageTitle } from 'components/PageTitle'
import { getCommonServerSideProps } from 'utils/get-common-server-side-props'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      ...(await getCommonServerSideProps(context)),
    },
  }
}

export default function Page() {
  const { isLoggedIn, isAuthLoading, userProfile } = useUserContext()
  const { t } = useTranslation()
  const router = useRouter()

  const [voucherCode, setVoucherCode] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isResolvingRedirect, setIsResolvingRedirect] = useState<boolean>(true)

  const isLoading = useMemo(() => {
    return isAuthLoading || !isLoggedIn || isResolvingRedirect
  }, [isAuthLoading, isLoggedIn, isResolvingRedirect])

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(e.target.value)
    setIsValid(e.target.value.length === 8)
  }

  useEffect(() => {
    if (!isAuthLoading) {
      const hasActiveSubscription = userProfile?.subscription?.active

      if (hasActiveSubscription) {
        router.replace(ONBOARDING_PAGES.success.path)
        return
      }

      if (!isLoggedIn) {
        router.push(
          encodeQuery(APP_PAGES.signup.path, {
            next: encodeQuery(APP_PAGES.redeem.path),
          })
        )
        return
      }

      setIsResolvingRedirect(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, isAuthLoading])

  const handleFormAction = async () => {
    try {
      const data = await fetchRedeemVoucher(voucherCode)

      if (!data.valid) {
        setIsValid(false)
        toast({
          type: 'error',
          content: t('redeem_error_voucher_invalid'),
        })
      } else {
        toast({
          type: 'success',
          content: t('redeem_success'),
        })

        router.push(encodeQuery(ONBOARDING_PAGES.success.path), undefined, {
          shallow: true,
        })
      }
    } catch {
      toast({
        type: 'error',
        content: 'Please try it again later.',
      })
    }
  }

  return (
    <Layout
      options={{
        isPrevHidden: true,
      }}
    >
      <PageTitle title={t('redeem_title')} />
      {isLoading ? (
        <PageContainer>
          <ContentContainer css={{ justifyContent: 'center' }}>
            <Spinner />
          </ContentContainer>
        </PageContainer>
      ) : (
        <PageContainer>
          <HeadingContainer xs="1rem">
            <Heading>{t('redeem_title')}</Heading>
            <Text>{t('redeem_subtitle')}</Text>
          </HeadingContainer>

          <ContentContainer>
            <AnimatedInput
              data-testid={'voucher-code-input'}
              autoFocus
              type="text"
              maxLength={8}
              onChange={handleValueChange}
              placeholder={t('redeem_placeholder')}
              value={voucherCode}
              invalid={!isValid}
            />
          </ContentContainer>

          <Threshold hasBackground>
            <StyledPrimaryButton
              onClick={() => handleFormAction()}
              disabled={voucherCode.length < 8}
            >
              {t('btn_continue')}
            </StyledPrimaryButton>
          </Threshold>
        </PageContainer>
      )}
    </Layout>
  )
}
